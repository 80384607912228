import { useEffect, useState } from "react"; 
import { GoalAreaOfFocus, ServiceProviderResponse,  StudentDropdownRef,  UserResponse } from "../../../../profile-sdk";
import { useXNGDispatch, useXNGSelector } from "../../../../context/store";
import { selectLoggedInClientAssignment, selectUser } from "../../../../context/slices/userProfileSlice";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import useFetchNullableResponse from "../../../../hooks/use_fetch_nullable_response";
import { API_CLIENTS, API_DISTRICTS, API_SERVICEPROVIDERS, API_STATESNAPSHOTS, API_STUDENTS, API_USERS } from "../../../../api/api";
import ReportsLayout from "../../reports_layout";
import ProgressReportForm from "./progress_reporting_form";
import { FiltersFormType } from "./progress_report_form_filter_data";
import EditableProgressReportingFilterForm from "./components/editable_progress_report_filter_form";
import CollapsableWrapperRoot from "./components/collapsable_wrapper_root";
import useProgressReporting from "./context/hooks/useProgressReporting";
import { placeholderForFutureLogErrorText } from "../../../../temp/errorText";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { providerNotFoundErrorActions } from "../../../../context/slices/providerNotFoundErrorSlice";
import { ProgressReportingActionType } from "./context/progress_reporting_actions"; 
import FullPageLoadingScreen from "../../../../design/high-level/common/full_page_loading_screen";

const ProgressReporting = () => { 
  const [selectedStudentOptions, setSelectedStudentOptions] = useState<StudentDropdownRef[]>([]);
  const [goalAreaOfFocus,setGoalsAreaOfFocus] = useState<GoalAreaOfFocus[]>()
  const dispatch = useXNGDispatch();
  const progressReportingDispatch = useProgressReporting()?.dispatch
  // School Campus Options Declaration 
  const primaryDistrict = useXNGSelector(selectLoggedInClientAssignment).authorizedDistricts![0];
  const stateInUs =  useXNGSelector(selectStateInUS) 
  const [isgettingDropdowns,setGettingDropDowns] = useState(false)
  const isEditReportActive = useProgressReporting()?.state?.isEditing
  //states
  const [_userProfile, setUserProfile] = useState<UserResponse>({});
  const [_providerProfile, setProviderProfile] = useState<ServiceProviderResponse>({});
  //SELECTORS
  const user = useXNGSelector(selectUser); 
  //current logged in client
  const loggedInClientId = useXNGSelector(selectClientID); /** "3b53449f-4e00-4b9a-aef7-e723e507e96f";  */
  
  const fetchedCampusOptions = useFetchNullableResponse(() =>
    API_DISTRICTS.v1DistrictsIdSchoolCampusesDropdownDisplaysGet(
      primaryDistrict.id!,
      stateInUs,
    ).then((v) => v.schoolCampuses!),
  ); 
  const generateReportSummaryHandler = (form_filters: FiltersFormType) => { };
  //selected campuses from child component selectors
  const selectedCampus =  useProgressReporting()?.state?.campusesSelectedForProgressReporting
  
  //service providers under a state and selected campuses
  const serviceProviders = useProgressReporting()?.state.allServiceProvidersUnderSelectedCampuses

  // school year
  const school_year_range_concatinated = useProgressReporting()?.state?.schoolYearRange 

  //reporting periods for schoolds
  const reportingPeriods = useProgressReporting()?.state?.reportingPeriods


  async function fetchAndSetUser() {
    if (user === undefined) throw Error(placeholderForFutureLogErrorText);
    const userResponse = await API_USERS.v1UsersIdGet(user?.id!, stateInUs);
    setUserProfile(userResponse);
    progressReportingDispatch({
      type: ProgressReportingActionType.SET_CURRENTY_LOGGED_IN_USER_PROFILE,
      payload: userResponse
    })
    let providerID = userResponse.clientAssignments!.find(
      (assignment) => assignment.client?.id === loggedInClientId,
    )?.serviceProviderProfile?.id;
    fetchAndSetProfile(providerID);
  }
  
  async function fetchAndSetProfile(providerID: string | undefined) {
    if (loggedInClientId === undefined) throw Error(placeholderForFutureLogErrorText);
    if (providerID === undefined) throw Error(placeholderForFutureLogErrorText);
    try {
      const providerResponse = await API_SERVICEPROVIDERS.v1ServiceProvidersIdGet(
        providerID,
        loggedInClientId,
        stateInUs,
      ); 
      progressReportingDispatch({
        type: ProgressReportingActionType.SET_CURRENTY_LOGGED_IN_SERVICE_PROVIDER,
        payload: providerResponse
      })
      setProviderProfile(providerResponse);
    } catch (err) {
      dispatch(
        providerNotFoundErrorActions.ACTION_ShowProviderNotFound({
          show: true,
          errorMsg: (err as Error).message,
        }),
      );
    }
  }
  
  async function fetchAndSetCaseloadServiceProviders() {
    try {
      setGettingDropDowns(true)
      const response =
      await API_SERVICEPROVIDERS.v1ServiceProvidersGetAllServiceProvidersInCampusesGet(
        loggedInClientId!,
        stateInUs,
        selectedCampus?.id
      );
      const serviceProviders = response.serviceProviderCaseloadOptions;  
      setGettingDropDowns(false)
      progressReportingDispatch({
        type:ProgressReportingActionType.SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES,
        payload:serviceProviders
      })
    } catch (e) {
      console.error(e);
    }
  }


  async function getStudentsByCampus(currentClientId: string, campusId: string) {
    try { 
      setGettingDropDowns(true)
      const commaSeperatedConcatinatedCampusIds = campusId 
      const response = await API_STUDENTS.v1StudentsGetStudentsDropdwnByCampusidsGet(currentClientId, stateInUs, commaSeperatedConcatinatedCampusIds)
      setGettingDropDowns(false)
      if(response.students) setSelectedStudentOptions(response.students)
      else{ 
        console.error("no students found ")}
    } catch (error) {
      console.error(error);
    }
  }
  
  async function getGoalsAndObjectives() {
    try {
      setGettingDropDowns(true)
      const response = await API_STATESNAPSHOTS.v1StateSnapshotsGoalAreasOfFocusGet(stateInUs)
      setGettingDropDowns(false)
      setGoalsAreaOfFocus(response.areasOfFocus)
      return response
    } catch (error) {
      console.error(error);
    }
  }

  async function getReportingPeriod(){
    try {
       setGettingDropDowns(true)
       const response = await API_CLIENTS.v1ClientsIdGetReportingPeriodsGet(loggedInClientId!,stateInUs,school_year_range_concatinated)
       setGettingDropDowns(false)
       progressReportingDispatch({
        type:ProgressReportingActionType.SET_REPORTING_PERIOD,
        payload:response?.reportingPeriods ?? []
       })
    } catch (error) {
      console.error(error)
    }
  }
  
  useEffect(() => {
    if(user && stateInUs){
      fetchAndSetUser();
    }else{
      console.error("no user and stateInUs was set")
    }
  }, [user,stateInUs]);
  
  useEffect(() => {
    if (stateInUs) { getGoalsAndObjectives() } 
    else { console.error("no stateInUs was selected") }
  }, [stateInUs])
  
  useEffect(() => {
    if (loggedInClientId && stateInUs){
       fetchAndSetCaseloadServiceProviders() }
    else { console.error("no loggedin client id or stateInUs found") }
  }, [loggedInClientId, stateInUs, selectedCampus])
  
  useEffect(() => {
    const campusId = selectedCampus?.id 
    if ((campusId)  &&  loggedInClientId) {
       getStudentsByCampus(loggedInClientId, campusId)
    } else {
       console.error(`no campusIds or logged in client id found`)
    }
  }, [loggedInClientId, stateInUs, selectedCampus])

  useEffect(()=>{
    if(school_year_range_concatinated&&loggedInClientId&&stateInUs){
        getReportingPeriod() 
    }
  },[school_year_range_concatinated,stateInUs,loggedInClientId])
   
  
  return ( 
    <>
    <ReportsLayout
    title="Progress Report" >
      {isEditReportActive ?
        <CollapsableWrapperRoot>
          <ProgressReportForm
            defaultOptions={{
              campuses: fetchedCampusOptions ?? [],
              serviceProviders: serviceProviders ?? [],
              student: selectedStudentOptions ??[],
              goalsAndObjectives:goalAreaOfFocus ??[] ,
              reportingPeriods : reportingPeriods ?? []
            }}
            onSubmitFormFilters={generateReportSummaryHandler}
          />
        </CollapsableWrapperRoot> : (
          <ProgressReportForm
            defaultOptions={{
              campuses: fetchedCampusOptions ?? [],
              serviceProviders: serviceProviders ?? [],
              student: selectedStudentOptions ??[],
              goalsAndObjectives:goalAreaOfFocus ??[],
              reportingPeriods : reportingPeriods ?? []
            }}
            onSubmitFormFilters={generateReportSummaryHandler}
          />
        )}
      {/* ediable form  */}
      {isEditReportActive && <EditableProgressReportingFilterForm />}
    </ReportsLayout> 
     {isgettingDropdowns && <FullPageLoadingScreen text={"Please wait"} pulse />}
    </>
  )
}

export default ProgressReporting