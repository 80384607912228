import { Controller, useForm } from "react-hook-form";
import GridSectionLayout from "../../../../design/high-level/common/grid_section_layout";
import { filters_form_schema, FiltersFormType, ProgressReportingFilterData } from "./progress_report_form_filter_data";
import { yupResolver } from "@hookform/resolvers/yup";
import { MSBSearchMultiselect } from "../../../../fortitude";
import msbMUIAutoCompleteFilterOptions from "../../../../utils/msb_mui_auto_complete_filter_options"; 
import XNGDropDown from "../../../../design/low-level/dropdown2";
import { Box, Button, Stack, Typography } from "@mui/material";
import { XNGDateField } from "../../../unposted_sessions/components/common/date_field";
import dayjs from "dayjs";
import useProgressReporting from "./context/hooks/useProgressReporting";
import { ProgressReportingActionType } from "./context/progress_reporting_actions";
import { useNavigate } from "react-router";
import { useEffect } from "react";
import { ROUTES_XLOGS } from "../../../../constants/URLs";

type SessionLogsFormProps = {
  defaultOptions: ProgressReportingFilterData;
  onSubmitFormFilters: (data: FiltersFormType) => void;
};

const ProgressReportForm = (props: SessionLogsFormProps) => {
  const {
    control,
    watch, 
    setValue
  } = useForm<FiltersFormType>({
    resolver: yupResolver(filters_form_schema)
  });
  const { dispatch } = useProgressReporting()  
  const selected_students = watch("student");
  const start_year = watch("date_filter_options.start_year");
  const end_year = watch("date_filter_options.end_year");
  const selected_school = watch("school"); 
  const reporting_period = watch("reporting_period")
  const atleastOneStudentSelected = Array.isArray(selected_students) && selected_students.length > 0 
  const isEditButtonEnabled = selected_school && atleastOneStudentSelected && start_year && end_year && reporting_period
  const navigate = useNavigate()
 
  useEffect(()=>{
    if(selected_school){ 
      setValue("service_providers",[])
      setValue("student",[])
    }
  },[selected_school]) 

  const openEditSection = () => {
    dispatch({
      type: ProgressReportingActionType.OPEN_PROGRESS_REPORTING_EDIT_SECTION
    })
  }

  const handleSummaryClick = () => {
     navigate(ROUTES_XLOGS.reports.progressSummary)
  }

  useEffect(() => {
    if (start_year && end_year) {
      dispatch({
        type: ProgressReportingActionType.SET_YEAR_RANGE_FOR_CAMPUS,
        payload: `${start_year}-${end_year}`
      })
    }
    setValue("reporting_period",{})
  }, [start_year, end_year])

  useEffect(()=>{
    console.log(reporting_period)
  },[reporting_period])


  const additional_filters_section = (
    <GridSectionLayout
      headerConfig={{
        title: "Additional Filter(s)",
        title_sx: {
          fontWeight: 700,
        },
      }}

      rows={[
        {
          cells: [
            <Typography sx={{ fontWeight: 700 }}>Select Students to Work On:</Typography>
          ]
        },
        {
          rowSx: {
            alignItems: "stretch",
            mb: "30px",
          },
          cellSizes: {
            xs: 12,
            sm: 8,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              px: "12px",
            },
          },
          cells: [
            <Controller
              control={control}
              name="school"
              render={({ field, fieldState: { error } }) => (
                <XNGDropDown
                  id={"school"}
                  variant="onPrimary"
                  size="small"
                  ref={field.ref}
                  name={field.name}
                  onBlur={field.onBlur}
                  useTypedDropDown={{
                    value: field.value,
                    items: props.defaultOptions.campuses ||
                      [],
                    getRenderedValue: (item) => item?.name || "",
                    onChange: (item) => {
                      field.onChange(item);
                      dispatch({
                        type: ProgressReportingActionType.SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION,
                        payload: item
                      })
                    },
                  }}
                  label={"Campus"}
                  fullWidth
                  maxwidth="100%"
                  useError={error?.message}
                />
              )}
            />,
            <Controller
              key={2}
              control={control}
              name={"service_providers"}
              render={({ field }) => {
                return (
                  <MSBSearchMultiselect
                    selectedOptions={field.value ?? []}
                    options={props.defaultOptions.serviceProviders}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onChange={(serviceProvider) => field.onChange(serviceProvider)}
                    renderOptionVariant="checkbox"
                    variant="no overflow after 1"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label="Service Provider"
                    sx={{ width: "100%", backgroundColor: "white" }}
                    autocompleteProps={{
                      disableCloseOnSelect: true,
                      filterOptions: msbMUIAutoCompleteFilterOptions(),
                    }}
                  />
                )
              }}
            />,
            <Controller
              key={1}
              control={control}
              name={"student"}
              render={({ field }) => {
                return (
                  <MSBSearchMultiselect
                    selectedOptions={field.value ?? []}
                    options={props.defaultOptions.student}
                    getOptionLabel={(option) => `${option.name} `}
                    onChange={(so) => field.onChange(so)}
                    renderOptionVariant="checkbox"
                    variant="no overflow after 1"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label="Student"
                    sx={{ width: "100%", backgroundColor: "white" }}
                    autocompleteProps={{
                      disableCloseOnSelect: true,
                      filterOptions: msbMUIAutoCompleteFilterOptions(),
                    }}
                  />
                )
              }}
            />,

          ],
        },
        {
          cells: [
            <Typography sx={{ fontWeight: 700 }}>Select Time Period to Report On: </Typography>
          ]
        },
        {
          rowSx: {
            alignItems: "stretch",
            mb: "30px",
          },
          cellSizes: {
            xs: 12,
            sm: 8,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              px: "12px"
            },
          },
          cells: [
            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Controller
                control={control}
                name="date_filter_options.start_year"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <XNGDateField
                      size="small"
                      fullWidth
                      label="Start Year"
                      views={["year"]}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        field.onChange(date?.format("YYYY"));
                      }}
                      sx={{ background: "white" }}
                    />
                  );
                }}
              />
              -
              <Controller
                control={control}
                name="date_filter_options.end_year"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <XNGDateField
                      sx={{ background: "white" }}
                      size="small"
                      fullWidth
                      label="End Year"
                      views={["year"]}
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date) => {
                        field.onChange(date?.format("YYYY"));
                      }}
                    />
                  );
                }}
              />
            </Stack>, 
            <Controller
              control={control}
              name="school"
              render={({ field, fieldState: { error } }) => (
                <XNGDropDown
                  id={"school"}
                  variant="onPrimary"
                  size="small"
                  ref={field.ref}
                  name={field.name}
                  onBlur={field.onBlur}
                  disabled
                  value={selected_school?.name ?? ""}
                  label={"Campus"}
                  fullWidth
                  maxwidth="100%"
                  useError={error?.message}
                />
              )}
            />,
            <Controller
              control={control}
              name="reporting_period"
              render={({ field, fieldState: { error } }) => (
                <XNGDropDown
                  id={"reporting_period"}
                  variant="onPrimary"
                  size="small"
                  ref={field.ref}
                  name={field.name}
                  useTypedDropDown={{
                    value: field.value,
                    items: props.defaultOptions.reportingPeriods ?? [],
                    getRenderedValue: (item) => item?.number || "",
                    onChange: (item) => {
                      field.onChange(item);
                    },
                  }}
                  label={"Reporting Period"}
                  fullWidth
                  maxwidth="100%"
                  useError={error?.message}
                />
              )}
            />,  
             

          ],

        },
        {
          cells: [
            <Typography sx={{ fontWeight: 700 }}>Select Areas to Report On: </Typography>
          ]
        },
        {
          rowSx: {
            alignItems: "stretch",
            mb: "30px",
          },
          cellSizes: {
            xs: 12,
            sm: 8,
            lg: 4,
          },
          useCellStyling: {
            sx: {
              bgcolor: "contrasts.1",
              py: "18px",
              px: "12px"
            },
          },
          cells: [
            <Controller
              key={0}
              control={control}
              name={"goals_and_objectives"}
              render={({ field }) => {
                return  (
                  <MSBSearchMultiselect
                    selectedOptions={field.value ?? []}
                    options={props.defaultOptions.goalsAndObjectives}
                    getOptionLabel={(option) => `${option.name}`}
                    onChange={(so) => field.onChange(so)}
                    renderOptionVariant="checkbox"
                    variant="no overflow after 1"
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    label="Area of focus"
                    sx={{ width: "100%", backgroundColor: "white" }}
                    autocompleteProps={{
                      disableCloseOnSelect: true,
                      filterOptions: msbMUIAutoCompleteFilterOptions(),
                    }}
                  />
                )  
              }}
            />
          ]
        },

        {
          rowSx: {
            mt: "3rem",
          },
          cellSizes: {
            xs: 12,
            sm: 4,
            lg: 4,
          },
          cells: [
            <Box key={0} display={"flex"} gap={2} justifyContent={"flex-start"}>
              <Button
                sx={{
                  width: "178px",
                  px: "2rem",
                  py: "1.5rem",
                }}
                type="submit"
                onClick={handleSummaryClick}
                disabled={!isEditButtonEnabled}
              >
                Summary
              </Button>
              <Button
                sx={{
                  width: "178px",
                  px: "2rem",
                  py: "1.5rem",
                }}
                disabled={!isEditButtonEnabled}
                onClick={openEditSection}
              >
                Edit
              </Button>
            </Box>,
          ],
        },


      ]}


    />
  );

  return (
    <div>
      {additional_filters_section}
    </div>
  )
}

export default ProgressReportForm