import { Box, Grid, SxProps, Typography } from '@mui/material'
import ProgressReportingStandardBtn from './progress_reporting_standard_btn'
import XNGDatePicker from '../../../../../design/low-level/calendar'
import EditGoalProgressFilterTable from './goal_edit_filter_table'
import ObjectivesEditFilterTable from './objectives_edit_filter_table'
import { SettingsPopup } from './popup'
import { useState } from 'react'
import LineChart from '../../../../../fortitude/amcharts/line_chart'
interface Edit_FILTER_ACTION_PROPS {
    title?: string
    onSubmit?: () => void
    sxOverRide?: SxProps
}
export enum FilterButtonTitle {
    Settings = "Settings",
    AddReportingPeriod = "Add Reporting Period",
    Print = "Print",
    Download = "Download",
    Post = "Post",
    Save = "Save"
}

const EditableProgressReportingFilterForm = () => {
    const dummyChartData = [
        { date: new Date(2023, 0, 1).getTime(), value: 50 },
        { date: new Date(2023, 0, 2).getTime(), value: 10 },
        { date: new Date(2023, 0, 3).getTime(), value: 60 },
        { date: new Date(2023, 0, 4).getTime(), value: 65 },
    ];
    const EDIT_FITER_ACTIONS: Edit_FILTER_ACTION_PROPS[] = [
        {
            title: FilterButtonTitle.Settings,
            onSubmit: () => { }
        },
        {
            title: FilterButtonTitle.AddReportingPeriod,
            onSubmit: () => { }
        },
        {
            title: FilterButtonTitle.Print,
            onSubmit: () => { }
        },
        {
            title: FilterButtonTitle.Download,
            onSubmit: () => { }
        },
        {
            title: FilterButtonTitle.Post,
            onSubmit: () => { }
        },
        {
            title: FilterButtonTitle.Save,
            onSubmit: () => { },
            sxOverRide: {
                background: "red",
                color: "white"
            }
        }
    ]
    const [isObjectiveProgressGraphVisible, setIsObjectiveProgressGraphVisible] = useState(false)
    const [isGoalGraphVisible, setIsGoalGraphVisible] = useState(false)

    return (
        <Box>
            <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap", flexDirection: "row" }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", gap: "10px" }}>
                    {
                        EDIT_FITER_ACTIONS
                            .map(
                                (editFilterAction: Edit_FILTER_ACTION_PROPS) =>
                                    <PopupRenderer buttonName={editFilterAction.title as FilterButtonTitle}>
                                        <ProgressReportingStandardBtn
                                            title={editFilterAction.title}
                                            sxOverRide={editFilterAction.sxOverRide as SxProps}
                                            onSubmit={editFilterAction.onSubmit}
                                            key={editFilterAction.title} />
                                    </PopupRenderer>
                            )
                    }
                </Box>
                <Box sx={{ width: "100%", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "24px", fontWeight: 700 }}>Report of Student's Progress Toward Individualized Education Plan Goals/Objectives</Typography>
                </Box>
                {/* district and reporting period */}
                <Grid direction={"row"} container>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>District: </Typography>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>dummy_district</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700, marginTop: "10px" }}>Reporting Period: </Typography>
                            <XNGDatePicker setValue={() => { }} />
                            <XNGDatePicker setValue={() => { }} />
                        </Box>
                    </Grid>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                        </Box>
                    </Grid>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>Grade: </Typography>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>9</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>Student Name: </Typography>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>dummy_student_name</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                            <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>Date of birth: </Typography>
                            <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 500 }}>10/10/1990</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px" }}>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>Campus: </Typography>
                            <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>Sample High school</Typography>
                        </Box>
                    </Grid>
                    <Grid item lg={6} >
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} />
                    </Grid>
                </Grid>

                <Box sx={{ border: "1px solid black", width: "100%", padding: "10px" }}>
                    <Grid container direction={"row"}>
                        <Grid item lg={3} >
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} >
                                <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>   Goal Area: </Typography>
                                <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 500 }}>  {"<<Area of Focus>>"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={6} >
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "5px" }} >
                                <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>  Goal Dates:</Typography>
                                <XNGDatePicker setValue={() => { }} />
                                <XNGDatePicker setValue={() => { }} />
                            </Box>
                        </Grid>
                        <Grid item lg={6} >
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} >
                                <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>
                                    Goal Status:
                                </Typography>
                                <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>
                                    {"<<Pull from goal>>"}
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                    <Typography sx={{ marginTop: "10px", fontSize: "16px", fontWeight: 700 }}>{"<<Goal Text>>"}</Typography>
                    <EditGoalProgressFilterTable />
                    <ProgressReportingStandardBtn onSubmit={() => { setIsGoalGraphVisible(!isGoalGraphVisible) }} title={isGoalGraphVisible ? 'Hide Graph' : 'Unhide Graph'} sxOverRide={{ marginTop: "20px" }} />
                    {/* line chart here */}
                    {isGoalGraphVisible && <LineChart
                        style={{ width: "70%", height: "500px" }}
                        data={dummyChartData}
                        xKey="date"
                        yKey="value"
                        xLabel="Date"
                        yLabel="Value"
                    />}
                </Box>


                <Box sx={{ border: "1px solid black", width: "100%", padding: "10px", marginLeft: "50px" }}>
                    <Grid container direction={"row"}>
                        <Grid item lg={3} >
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} >
                                <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>   Objective Area: </Typography>
                                <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>  {"Speech Therapy"}</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={6} >
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "5px" }} >
                                <Typography variant="subtitle2" sx={{ fontSize: "16px", fontWeight: 700 }}>  Objective Dates:</Typography>
                                <XNGDatePicker setValue={() => { }} />
                                <XNGDatePicker setValue={() => { }} />
                            </Box>
                        </Grid>
                        <Grid item lg={6} >
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", marginTop: "10px" }} >
                                <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 700 }}>
                                    Goal Status:
                                </Typography>
                                <Typography variant="subtitle2" sx={{ marginBottom: "1rem", fontSize: "16px", fontWeight: 500 }}>
                                    {"Active"}
                                </Typography>
                            </Box>

                        </Grid>
                    </Grid>
                    <Typography sx={{ marginTop: "10px", fontSize: "16px", fontWeight: 700 }}>{"<<Goal Text>>"}</Typography>
                    <ObjectivesEditFilterTable />
                    <ProgressReportingStandardBtn
                        onSubmit={() => { setIsObjectiveProgressGraphVisible(!isObjectiveProgressGraphVisible) }}
                        title={isObjectiveProgressGraphVisible ? 'Hide Graph' : 'Unhide Graph'} sxOverRide={{ marginTop: "20px" }} />

                    {/* line chart here */}
                    {isObjectiveProgressGraphVisible &&
                        <LineChart
                            style={{ width: "70%", height: "500px" }}
                            data={dummyChartData}
                            xKey="date"
                            yKey="value"
                            xLabel="Date"
                            yLabel="Value"
                        />}
                </Box>
                <Box sx={{ width: "100%", height: "150px" }} />
            </Box>
        </Box>
    )
}


const PopupRenderer: React.FC<{ buttonName: FilterButtonTitle, children: React.ReactNode }> = ({ buttonName, children }) => {
    const renderedComponent: { [key in FilterButtonTitle]: React.ReactElement } = {
        [FilterButtonTitle.Settings]: <SettingsPopup > {children}</SettingsPopup>,
        [FilterButtonTitle.AddReportingPeriod]: <>{children}</>,
        [FilterButtonTitle.Print]: <>{children}</>,
        [FilterButtonTitle.Download]: <>{children}</>,
        [FilterButtonTitle.Post]: <>{children}</>,
        [FilterButtonTitle.Save]: <>{children}</>,

    }

    return renderedComponent[buttonName] as React.ReactElement
}




export default EditableProgressReportingFilterForm