import { useState } from "react";
import { DefaultCareProvisionsResponse } from "../../../../profile-sdk";
import dayjs from "dayjs";
import { SessionResponse } from "../../../../session-sdk";
import { API_STATESNAPSHOTS } from "../../../../api/api";
import useEffectSkipMount from "../../../../hooks/use_effect_after_mount";
import { useXNGDispatch, useXNGSelector } from "../../../../context/store";
import { selectClientID } from "../../../../context/slices/loggedInClientSlice";
import { useMutation, useQuery } from "@tanstack/react-query";
import { pushError } from "../../../../context/slices/errorEntries";
import { getErrorFromFetchAPIWhenResponseIsOnlyString } from "../../../../utils/get_error_from_fetch_api_when_response_is_only_string";

export interface UseFetchedDefaultCareProvisionsProps {
  draftSession: SessionResponse;
  stateInUS: string;
}

export function useFetchedDefaultCareProvisions(props: UseFetchedDefaultCareProvisionsProps) {
  const { draftSession, stateInUS } = props;
  const clientID = useXNGSelector(selectClientID);

  const dispatch = useXNGDispatch();

  const {
    mutate,
    data: defaultCareProvisions,
    error,
  } = useMutation({
    mutationKey: [
      "defaultCareProvisions",
      draftSession.service?.type?.id,
      draftSession.service?.area?.id,
      stateInUS,
      clientID,
    ],
    mutationFn: async () => {
      const service = draftSession.service;
      if (!service || !service.area?.id || !service.type?.id) {
        throw new Error("Session's service data point is undefined or missing IDs");
      }
      const sessionDate = dayjs(draftSession.meetingDetails?.startTime).toDate();
      return await API_STATESNAPSHOTS.v1StateSnapshotsByDateDefaultCareProvisionsGet(
        service.type.id,
        service.area.id,
        stateInUS,
        sessionDate,
        clientID,
      );
    },
    retry: false,
  });

  useEffectSkipMount(() => {
    pushErrorAsync();

    async function pushErrorAsync() {
      if (!error) return;

      dispatch(
        pushError({
          titleID: "Failed to acquire default care provisions for session",
          message: await getErrorFromFetchAPIWhenResponseIsOnlyString(error),
        }),
      );
    }
  }, [error]);

  useEffectSkipMount(() => {
    mutate();
  }, [draftSession.studentJournalList?.length]);

  return defaultCareProvisions ?? null;
}
