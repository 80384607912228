export interface ProgressReportingAction {
    type: ProgressReportingActionType;
    payload?: any; // Use specific type if possible
}


export enum ProgressReportingActionType { 
    /**
     * SETTINGS POPUP ACTIONS
     */
    TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS = "TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS",
    TOGGLE_SHOW_CHART_FROM_SETTINGS = "TOGGLE_SHOW_CHART_FROM_SETTINGS",
    TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS = "TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS",
    TOGGLE_LAST_SESSION_FROM_SETTINGS = "TOGGLE_LAST_SESSION_FROM_SETTINGS",
    TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS = "TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS",
    TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS = "TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS",
    SET_SELECTED_SERVICE_PROVIDER_FROM_SETTINGS = "SET_SELECTED_SERVICE_PROVIDER_FROM_SETTINGS",
    /***
     * GENERAL ACTIONS  
     */
    OPEN_PROGRESS_REPORTING_EDIT_SECTION = "OPEN_PROGRESS_REPORTING_EDIT_SECTION",
    CLOSE_PROGRESS_REPORTING_EDIT_SECTION = "CLOSE_PROGRESS_REPORTING_EDIT_SECTION",
    SET_CURRENTY_LOGGED_IN_USER_PROFILE = "SET_CURRENTY_LOGGED_IN_USER_PROFILE",
    SET_CURRENTY_LOGGED_IN_SERVICE_PROVIDER = " SET_CURRENTY_LOGGED_IN_SERVICE_PROVIDER",
    SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION = "SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION",
    SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES = "SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES",
    SET_YEAR_RANGE_FOR_CAMPUS = "SET_YEAR_RANGE_FOR_CAMPUS",
    SET_REPORTING_PERIOD = "SET_REPORTING_PERIOD"

}
