import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { ClientRef, UserResponse } from "../../../../profile-sdk";
import {
  selectLoggedInClientAssignment,
  selectUser,
} from "../../../../context/slices/userProfileSlice";
import { useXNGDispatch, useXNGSelector } from "../../../../context/store";
import { selectStateInUS } from "../../../../context/slices/stateInUsSlice";
import { useNavigate } from "react-router";
import { useMemo, useState } from "react";
import { placeholderForFutureLogErrorText } from "../../../../temp/errorText";
import { produce } from "immer";
import { API_USERS } from "../../../../api/api";
import { pushError } from "../../../../context/slices/errorEntries";
import { getErrorFromFetchAPIWhenResponseIsOnlyString } from "../../../../utils/get_error_from_fetch_api_when_response_is_only_string";

interface ClientSelectorDetails {
  selectableClientOptions: ClientRef[];
  changeClientMutation: UseMutationResult<UserResponse, Error, ClientRef, unknown>;
  selectedClient: ClientRef;
  setSelectedClient: React.Dispatch<React.SetStateAction<ClientRef>>;
}

export function useClientSelectorDetails(): ClientSelectorDetails {
  const userProfile = useXNGSelector(selectUser);
  const dispatch = useXNGDispatch();
  const loggedInClientAssignment = useXNGSelector(selectLoggedInClientAssignment);
  const stateInUs = useXNGSelector(selectStateInUS);
  const navigate = useNavigate();

  const selectableClientOptions = useMemo(() => {
    return (
      userProfile?.clientAssignments
        ?.filter((ca) => (ca.authorizedDistricts?.length ?? 0) > 0)
        .map((ca) => ca.client as ClientRef) ?? []
    );
  }, [userProfile]);
  const [selectedClient, setSelectedClient] = useState<ClientRef>(loggedInClientAssignment.client!);
  const changeClientMutation = useMutation({
    mutationFn: async (newClient: ClientRef) => {
      if (!userProfile?.id) throw new Error(placeholderForFutureLogErrorText);

      const patchUserRequest = produce(userProfile, (draft) => {
        draft.loggedInClientId = newClient.id;
      });

      return API_USERS.v1UsersIdPatch(userProfile.id, stateInUs, patchUserRequest);
    },
    onSuccess: () => navigate(0),
    onError: (error) => {
      displayError();

      async function displayError() {
        dispatch(
          pushError({
            titleID: "Failed to log user into new client!",
            message: await getErrorFromFetchAPIWhenResponseIsOnlyString(error),
          }),
        );
      }
    },
  });

  return { selectableClientOptions, changeClientMutation, selectedClient, setSelectedClient };
}
