import * as yup from "yup";
import { GoalAreaOfFocus, ReportingPeriod, SchoolCampusRef, ServiceProviderRef, StudentDropdownRef } from "../../../../profile-sdk";
import session_date_filter_options from "../../../../data/get_session_date_filter_options";
import dayjs from "dayjs";

export const filters_form_schema = yup.object().shape({

  service_providers: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        firstName: yup.string(),
        lastName: yup.string(),
        email: yup.string(),
      }),
    )
    .required( "Please select at least one service provider."),
  student: yup
    .array()
    .of(yup.object().shape({ id: yup.string(), name: yup.string() }))
    .min(1, "Please select at least one student."),
  date_filter_options: yup
    .object()
    .shape({
      start_year: yup.string(),
      end_year: yup.string(),
      week: yup.object().shape({
        start: yup.date(),
        end: yup.date(),
        week_number: yup.number().nullable()
      }),
      start_date: yup.date(),
      end_date: yup.date()
    }).required("Please select a date filter option."), 
  school: yup.object().shape({
    id: yup.string(),
    name: yup.string(),
    
  }).required("Please select a school."),
  reporting_period: yup.object().shape(
    {
      number:yup.number(),
      date:yup.string()
    }
  ).required("Please select a reporting period."),
  goals_and_objectives: yup.array().of(yup.object({id:yup.string(),name:yup.string()})).min(1, "Please select at least one goal and objective."),

})

export type FiltersFormType = yup.InferType<typeof filters_form_schema>;


export type ProgressReportingFilterData = {
  campuses: SchoolCampusRef[];
  serviceProviders: ServiceProviderRef[];
  student: StudentDropdownRef[];  
  goalsAndObjectives: GoalAreaOfFocus[];
  reportingPeriods: ReportingPeriod[]
}