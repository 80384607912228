import { useEffect, useRef, useState } from "react" 
import useProgressReporting from "../context/hooks/useProgressReporting"
import { Box, Typography } from "@mui/material"
import XNGToggle from "../../../../../design/low-level/button_toggle"
import ProgressReportingPopupRoot from "./progress_reporting_popup_root"
import XNGDropDown from "../../../../../design/low-level/dropdown2"; 
import { ProgressReportingActionType } from "../context/progress_reporting_actions"
import { ServiceProviderResponse } from "../../../../../profile-sdk"


export const SettingsPopup =({children}:{children:React.ReactNode}) => {
    const [isObjective,setIsObjective] = useState(false)
    const [isChart,setIsChart] = useState(false)
    const [isAggregatedData,setIsAggregatedData] = useState(false)
    const [isLastSession,setIsLastSession] = useState(false)
    const [isTeamAggregatedData,setIsTeamAggregatedData] = useState(false)
    const [isOnlyActiveGoals,setIsOnlyActiveGoals] = useState(false)
    const [selectedServiceProvider,setSelectedServiceProvider] = useState<ServiceProviderResponse>()
    const serviceProviderLoggedInRef = useRef<HTMLDivElement>(null) 
    const serviceProvider = useProgressReporting()?.state?.currentlyLoggedInServiceProvider 
    const dispatch = useProgressReporting()?.dispatch
    const {allServiceProvidersUnderSelectedCampuses} = useProgressReporting()?.state
    const {showAggregatedData,showLastSession,showTeamAggregatedData,showChart,showActiveGoals,showObjectives,serviceProviderSelected} = useProgressReporting()?.state?.settingsPopup
    
    useEffect(()=>{
      if(serviceProviderSelected){
        setSelectedServiceProvider(serviceProviderSelected)
      }
    },[serviceProviderSelected])
    useEffect(()=>{ 
        setSelectedServiceProvider(serviceProvider)
    },[serviceProvider])

    useEffect(()=>{
        dispatch({
          type:ProgressReportingActionType.TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS 
        })
    },[isObjective])

    useEffect(()=>{
        dispatch({
          type:ProgressReportingActionType.TOGGLE_SHOW_CHART_FROM_SETTINGS 
        })
    },[isChart])

    useEffect(()=>{
        dispatch({
          type:ProgressReportingActionType.TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS 
        })
    },[isAggregatedData])

    useEffect(()=>{
        dispatch({
          type:ProgressReportingActionType.TOGGLE_LAST_SESSION_FROM_SETTINGS 
        })
    },[isLastSession])

    useEffect(()=>{
        dispatch({
          type:ProgressReportingActionType.TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS 
        })
    },[isTeamAggregatedData])

    useEffect(()=>{
        dispatch({
          type:ProgressReportingActionType.TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS 
        })
    },[isOnlyActiveGoals])



    const PopupForm =()=>{
  
      return(
          <Box sx={{display:"flex",flexDirection:"column",gap:"10px",padding:"20px"}}>
                <XNGToggle value={isObjective} onToggle={()=>setIsObjective(prev=>!prev)} label='Show Objectives'/>
                <XNGToggle value={showChart} onToggle={()=>setIsChart(prev=>!prev)} label='Show Chart'/>
                  {/* service provider logged in */}
                  <Typography sx={{fontWeight:700}}>Apply X Logs Data</Typography>
                  <Box sx={{marginLeft:"15px",marginTop:"10px"}}>
                        <XNGDropDown
                                      id={"service_provider_logged_in"}
                                      variant="onPrimary"
                                      size="small" 
                                      ref={serviceProviderLoggedInRef}
                                      name={"service_provider_logged_in"}
                                      value={selectedServiceProvider}
                                      defaultValue={selectedServiceProvider}
                                      onChange={(e)=>setSelectedServiceProvider(e.target.value)} 
                                      items={allServiceProvidersUnderSelectedCampuses}
                                      label={"Select Service Provider"}
                                      fullWidth
                                      maxwidth="100%" 
                                    />
                      <XNGToggle value={showAggregatedData} onToggle={()=>setIsAggregatedData(prev=>!prev)} label='Aggregated Data'/>
                      <XNGToggle value={showLastSession} onToggle={()=>setIsLastSession(prev=>!prev)} label='Last Session Data'/>
                      <XNGToggle value={showTeamAggregatedData} onToggle={()=>setIsTeamAggregatedData(prev=>!prev)} label='Team Aggregated Data'/>
                  </Box>
                        <Box sx={{marginTop:"10px"}}> 
                                <XNGToggle value={isOnlyActiveGoals} onToggle={()=>setIsOnlyActiveGoals(prev=>!prev)} label='Show Only Active Goals'/>
                        </Box>

          </Box>
      )
    }
    return (
                  <ProgressReportingPopupRoot popupForm={<PopupForm/>}>
                       {children}
                  </ProgressReportingPopupRoot>
              ) 
           
  }