import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { GREY_COLOR } from '../../../../../design/high-level/xngbigtable/constants/grey_color'; 
import ProgressReportingStandardBtn from './progress_reporting_standard_btn';
import { StudentFitlerTableTitle } from '../utils/progress_report_fitler_edit_data_grid_helper'; 
import { XNGRadio } from '../../../../../design';
import { FormControlLabel, Grid, RadioGroup, TextareaAutosize } from '@mui/material';

interface Data {
  period: string;
  objectiveStartDate: string;
  objectivesEndDate: string;
  makingAdequateProgress: string;
  progressMeasured: string;
  generalComments?: string;
  reportedProgress?: string;
  posted:boolean
}

enum StateKeys {
  period = "period",
  objectiveStartDate = "objectiveStartDate",
  objectivesEndDate = "objectivesEndDate",
  makingAdequateProgress = "makingAdequateProgress",
  progressMeasured = "progressMeasured",
  generalComments = "generalComments",
  reportedProgress = "reportedProgress",
}

function createData(
  period: string,
  objectiveStartDate: string,
  objectivesEndDate: string,
  makingAdequateProgress: string,
  progressMeasured: string,
  generalComments: string,
  reportedProgress : string,
  posted:boolean
): Data {
  return { period, objectiveStartDate, objectivesEndDate, makingAdequateProgress, progressMeasured , generalComments, reportedProgress,posted };
}

const rows = [
  createData("1","11/10/23","06/10/23","yes/no","<<xlogs data>>","<<open text field>>","<<auto popuate once>>",true), 
  createData("2","11/10/22","06/10/22","yes/no","<<xlogs data>>","<<open text field>>","<<auto popuate once>>",false), 
];

const ObjectivesEditFilterTable: React.FC = () => {
  return (
    <TableContainer sx={{ minWidth: 800,flexShrink:0 }} component={Paper}>
            <Grid container>
                   <Grid item xs={2} lg={2}> 
                          <ProgressReportingStandardBtn title="Add Reporting period" onSubmit={()=>null} sxOverRide={{marginBottom:"5px",width:"80%"}}/>
                   </Grid>
                   <Grid item lg={2}/>
                   <Grid item xs={2} lg={2} ml={{ xs:17}}>  
                          <ProgressReportingStandardBtn title="Edit Progress Data" onSubmit={()=>null} sxOverRide={{marginBottom:"5px",width:"98%"}}/>
                   </Grid>
             </Grid> 
      <Table sx={{ minWidth: 800 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ bgcolor: GREY_COLOR}}>
            {
              Object.entries(StudentFitlerTableTitle).map(([key, value]) => ( 
                <TableCell sx={{ border: '3px solid rgba(0, 0, 0, 0.16)' }} align="center" key={key} >{value}</TableCell>
              ))
            } 
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,idx) => (
            <TableRow
              key={row.period} 
            > 
              {
                Object.entries(row).map(([key, value]) => (
                  <ConditionalProgressReportDataGridCellRenderer key={key} identifier={key as StateKeys} value={value} />
                ))
              } 
              <TableCell sx={{ display: "grid", placeContent: "center",marginTop:"10px" }}>
              {
                   row.posted ? 
                   <ProgressReportingStandardBtn title='Unpost' onSubmit={()=>null} sxOverRide={{marginLeft:"10px",background:"red"}} /> :
                   <ProgressReportingStandardBtn title='Post' onSubmit={()=>null} sxOverRide={{marginLeft:"10px"}} /> 
                 }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


const ConditionalProgressReportDataGridCellRenderer:React.FC<{identifier:StateKeys,value:string}> = ({identifier,value}) => {
   let children = null
   const [toggleStatus, setToggleStatus] = React.useState(false)
   const [comment, setComment] = React.useState("")
   children = {
     [StateKeys.period]: <TableCell align="center"  sx={{borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,borderLeft: "3px solid rgba(0, 0, 0, 0.16)",width: "5%"}} >{value}</TableCell> ,
     [StateKeys.objectiveStartDate]: <TableCell align="center" sx={{borderRight: "3px solid rgba(0, 0, 0, 0.16)",minWidth:"120px", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,width:"15%"}}>{value}</TableCell> ,
     [StateKeys.objectivesEndDate]: <TableCell align="center"  sx={{borderRight: "3px solid rgba(0, 0, 0, 0.16)",minWidth:"120px", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,width:"15%"}} >{value}</TableCell> ,
     [StateKeys.makingAdequateProgress]: (<TableCell sx={{borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,width:"10%"}}> 
                                                  <RadioGroup name='makeAdequateProgress' sx={{display:"flex",flexDirection:"row",flexWrap:"nowrap",justifyContent:"center",alignItems:"center"}}> 
                                                     <FormControlLabel  value={"Yes"} control={<XNGRadio checked={toggleStatus} />} label="Yes" onChange={() => setToggleStatus(!toggleStatus)} />
                                                     <FormControlLabel value={"No"} control={<XNGRadio checked={!toggleStatus} />} label="No" onChange={() => setToggleStatus(!toggleStatus)} /> 
                                                  </RadioGroup>
                                          </TableCell>), 
     [StateKeys.progressMeasured]: <TableCell align="center"  sx={{borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,width:"20%" }} >{value}</TableCell>, 
     [StateKeys.generalComments]: (
     <TableCell align="center"  sx={{borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,width:"30% !important" }}  >
         <TextareaAutosize style={{minHeight:"100px",border:"3px solid rgba(0, 0, 0, 0.16)", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,resize:"none",width:"100%"}} placeholder='General Comments' value={comment} onChange={(e) => setComment(e.target.value)} />
     </TableCell>) ,
     [StateKeys.reportedProgress]: <TableCell align="center"  sx={{borderRight: "3px solid rgba(0, 0, 0, 0.16)", borderTop:  "3px solid rgba(0, 0, 0, 0.16)" ,width:"10%",minWidth:"150px" }}>{value}</TableCell> 
    
   }[identifier]


   return children
};

export default ObjectivesEditFilterTable;
 