import React, { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// Define TypeScript types for props
type LineChartProps<T> = {
  data: T[];
  xKey: keyof T;
  yKey: keyof T;
  xLabel?: string;
  yLabel?: string;
  style:React.CSSProperties
};

const LineChart = <T extends Record<string, any>>({
  data,
  xKey,
  yKey,
  xLabel = "X Axis",
  yLabel = "Y Axis",
  style
}: LineChartProps<T>) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(chartRef.current!);

    // Apply themes
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    // Create axes
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.2,
        baseInterval: { timeUnit: "day", count: 1 }, // Adjust as necessary
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Create series
    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: yKey as string,
        valueXField: xKey as string,
        tooltip: am5.Tooltip.new(root, {
          labelText: `{${yKey as string}}`,
        }),
      })
    );

    // Set data
    series.data.setAll(data);

    // Add cursor
    chart.set("cursor", am5xy.XYCursor.new(root, {}));

    // Cleanup on unmount
    return () => {
      root.dispose();
    };
  }, [data, xKey, yKey, xLabel, yLabel]);


  return <div ref={chartRef} style={{ width: "100%", height: "500px",...style }}></div>;
};

export default LineChart;
