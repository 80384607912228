import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export type ErrorEntry = {
  titleID: string;
  message: string;
};

const initialState: ErrorEntry[] = [];

const errorSlice = createSlice({
  name: "errorEntries",
  initialState,
  reducers: {
    pushError: (state, action: PayloadAction<ErrorEntry>) => {
      const { titleID, message } = action.payload;
      const existingIndex = state.findIndex((error) => error.titleID === titleID);
      if (existingIndex === -1) {
        state.push({ titleID, message });
      } else {
        console.error(`Error with titleID ${titleID} already exists.`);
      }
    },
    popError: (state) => {
      state.pop();
    },
    clearErrors: () => {
      return [];
    },
  },
});

export const { pushError, popError, clearErrors } = errorSlice.actions;

export const selectErrorEntries = (state: RootState) => state.errorEntriesReducer;

export default errorSlice.reducer;
