import { TabInnerViewportLayout } from "../../layouts/inner_viewport_headers";
import { useState, useEffect } from "react";
import { EditGoalListModal } from "./edit_goal_list_modal";
import { Box, Divider } from "@mui/material";
import { useFetchedActiveGoalsByStudentInSessionIndex } from "./api-hooks/use_fetch_active_goals";
import { UnifiedGoal, useUnifiedGoals } from "./hooks/use_unified_goals";
import { StudentGoalOption } from "./components/student_goal_option";
import { useNotatorTools } from "../../tools";
import { NotatorTabViewPropsBase } from "../../types/tab_view_props_base";
import { GoalRef } from "../../../../session-sdk";

interface GoalsObjectivesTabViewProps extends NotatorTabViewPropsBase {}

export default function GoalsObjectivesTabView(props: Readonly<GoalsObjectivesTabViewProps>) {
  const { selectedStudentIndex } = props;
  const { draftSession, studentAPICaches } = useNotatorTools();
  const fetchedActiveGoals = useFetchedActiveGoalsByStudentInSessionIndex(selectedStudentIndex);
  const [goalRefs, setGoalRefs] = useState<GoalRef[]>([])

  useEffect(() => {
    if(studentAPICaches) {
      setGoalRefs(studentAPICaches[selectedStudentIndex].studentProfile.spedDossier?.prescribedCareProvisionsLedger?.goals!)
    }
  }, [studentAPICaches, selectedStudentIndex])

  let unifiedGoals: null | undefined | UnifiedGoal[] = null;

  unifiedGoals = useUnifiedGoals({
    goalRefs: goalRefs ?? [],
    goalDisplays: fetchedActiveGoals ?? [],
    selectedStudentIndex,
  });

  if(unifiedGoals && draftSession.status && draftSession.status > 1) {
    unifiedGoals = unifiedGoals.filter((goal) => goal.goalProgression != null);
  }

  const [showGoalsModal, setShowGoalsModal] = useState<boolean>(false);

  return (
    <>
      {/* Modals */}
      <EditGoalListModal
        fetchedActiveGoals={fetchedActiveGoals}
        open={showGoalsModal}
        onClose={() => setShowGoalsModal(false)}
        selectedStudentIndex={selectedStudentIndex}
      />

      {/* Content */}
      <TabInnerViewportLayout
        title="Goals & Objective Progress Tracking"
        useLink={{ text: "Edit Goal List", onClick: () => setShowGoalsModal(true) }}
      >
        {unifiedGoals?.map((ug, i) => {
          return (
            <Box key={`${selectedStudentIndex}-${i}`}>
              {i !== 0 && <Divider />}

              <StudentGoalOption unifiedGoal={ug} selectedStudentIndex={selectedStudentIndex} />
            </Box>
          );
        })}
      </TabInnerViewportLayout>
    </>
  );
}
