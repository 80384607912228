
import { ProgressReportingStateType } from "../../types/progress_reporting_context_type"
import { ProgressReportingAction, ProgressReportingActionType } from "../progress_reporting_actions"

 
export function progressReportingReducer(state:ProgressReportingStateType,action:ProgressReportingAction):ProgressReportingStateType{
    switch(action.type){
        case ProgressReportingActionType.OPEN_PROGRESS_REPORTING_EDIT_SECTION: 
            const updatedState:ProgressReportingStateType = {
                ...state,
                isEditing: true
            }
            return updatedState
        case ProgressReportingActionType.CLOSE_PROGRESS_REPORTING_EDIT_SECTION:  
            return {
                ...state,
                isEditing: false
            }
        case ProgressReportingActionType.SET_CURRENTY_LOGGED_IN_USER_PROFILE:
            return {
                ...state,
                currentlyLoggedInUserProfile: action.payload
            }
        case ProgressReportingActionType.SET_CURRENTY_LOGGED_IN_SERVICE_PROVIDER:
            return {
                ...state,
                currentlyLoggedInServiceProvider: action.payload
            }
        case ProgressReportingActionType.SET_CAMPUS_SELECTED_FOR_PROGRESS_REPORT_GENERATION:
            return {
                ...state,
                campusesSelectedForProgressReporting:action.payload
            }
        /**
         * SETTINGS REDUCERS
         */
        case ProgressReportingActionType.TOGGLE_SHOW_OBJECTIVES_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showObjectives: !state.settingsPopup.showObjectives
                }
            }

        case ProgressReportingActionType.TOGGLE_SHOW_CHART_FROM_SETTINGS:
                return {
                    ...state,
                    settingsPopup: {
                        ...state.settingsPopup,
                        showChart: !state.settingsPopup.showChart
                    }
                }
        case ProgressReportingActionType.TOGGLE_SHOW_AGGREGATED_DATA_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showAggregatedData: !state.settingsPopup.showAggregatedData
                }
            }
        case ProgressReportingActionType.TOGGLE_LAST_SESSION_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showLastSession: !state.settingsPopup.showLastSession
                }
            }

        case ProgressReportingActionType.SET_SELECTED_SERVICE_PROVIDER_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    serviceProviderSelected: action.payload
                }
            }    
        case ProgressReportingActionType.TOGGLE_TEAM_AGGREGATED_DATA_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showTeamAggregatedData: !state.settingsPopup.showTeamAggregatedData
                }
            }
        case ProgressReportingActionType.TOGGLE_SHOW_ACTIVE_GOALS_FROM_SETTINGS:
            return {
                ...state,
                settingsPopup: {
                    ...state.settingsPopup,
                    showActiveGoals: !state.settingsPopup.showActiveGoals
                }
            }
        case ProgressReportingActionType.SET_ALL_SERVICE_PROVIDERS_UNDER_SELECTED_CAMPUSES:
            return {
                ...state,
                allServiceProvidersUnderSelectedCampuses: action.payload
            }
        case ProgressReportingActionType.SET_YEAR_RANGE_FOR_CAMPUS:
            return {
                ...state,
                schoolYearRange:action.payload
            }
        case ProgressReportingActionType.SET_REPORTING_PERIOD:
            console.log("reporting period set",action.payload)
            return {
                ...state,
                reportingPeriods:action.payload
            }
        default:
            const exhaustiveCheck: any = action.type;
            throw new Error(`Unhandled progress reporting action: ${exhaustiveCheck}`);
    }
} 