import { DataGrid } from "@mui/x-data-grid";
import './progress_summary_view.css'
import { progress_summary_manager,  ProgressSummaryDataGrid_MUI_RowDataProps } from "./utils/progress_summary_data_grid_manager";
import { useEffect, useState } from "react";
import ReportsLayout from "../../reports_layout";
import { SkeletonLoading } from "../../../admin/views/district_profile/components";
import { Box, Button } from "@mui/material";
import { useDataGridBase } from "../../../../hooks/use_datagrid_base";
export function ProgressSummary() { 
    const [isLoading, setIsLoading] = useState(false)
    progress_summary_manager.setProgressDataSummaryColumnNames().setProgressDataSummaryRows() 
    const rows = progress_summary_manager._progressDataGridRow
    const cols = progress_summary_manager._progressDataGridColumn  
    const dataGrid = useDataGridBase<ProgressSummaryDataGrid_MUI_RowDataProps>({rows,columns:cols})
    useEffect(() => {
        const { rows, columns } = dataGrid
        const isRowOrColsEmpty = rows.length === 0 || columns.length === 0
        if (isRowOrColsEmpty) setIsLoading(true)
        else setIsLoading(false)
    }, [dataGrid])
    return (
        <ReportsLayout title="Summary" gutterTop>
            {isLoading ? <SkeletonLoading height="500px" width="95vw" /> : (
                <Box>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%" }}>
                        <Button
                            sx={{
                                width: "178px",
                                px: "2rem",
                                py: "1.5rem",
                            }}
                        >
                            Download
                        </Button>
                    </Box>
                    <DataGrid
                        rows={dataGrid.rows}
                        columns={dataGrid.columns}
                        hideFooterPagination
                        disableColumnMenu={true}
                        hideFooter
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow data_grid_text_clr' : ' data_grid_text_clr'}
                        checkboxSelection={false}
                        disableRowSelectionOnClick
        
                        sx={{ padding: "0px 10px 20px 10px", fontWeight: "400", fontSize: "14px", color: "black", maxHeight: "698px", marginTop: "20px",      '& .MuiDataGrid-columnHeaders': {
                            textAlign: 'center',
                          },
                          '& .MuiDataGrid-columnHeaderTitle': {
                            textAlign: 'center',
                            width: '100%',
                          }, }}
                    />
                </Box>
            )}
        </ReportsLayout>
    )
}